<template>
    <ul>
      <!-- <li v-for="person in persons" :key="person.id">
        {{ person.name }} {{ person.preposition ? person.preposition + " " : "" }} {{ person.surname }}, {{ person.email }}
      </li> -->
    </ul>
</template>
  
  <script>
    import { liveQuery } from "dexie";
    import { useObservable } from "@vueuse/rxjs";
    import { db } from "../db";
    import { server } from "../server";
  
    export default {
      name: "personList",
      setup() {
        return {
          db,
          persons: useObservable(
            liveQuery(() => db.persons.where("onServer").equals(0).toArray())
          ),
        };
      },
      created() {
        addEventListener("online", this.syncPersons);
      },
      methods: {
        syncPersons() {
          this.sendPersonListToServer();
        },
        async sendPersonListToServer() {
          server.sendPersonList(this.persons);
        },
      },
    };
  </script>
  