import axios from "axios";
import { db } from './db';

export const server = {

    async sendPersonList(personList) {
        console.log("sending " + personList.length)
        axios({
            method: 'post',
            url: process.env.VUE_APP_STORAGE_URL + '/api/person',
            data: personList
        })
        .then(response => {
            console.log(response);
            let ids = personList.map(person => person.id);
            db.persons.where('id').anyOf(ids).modify({onServer: 1}).then(updated => {
                if (updated)
                console.log ("Set onServer to true for " + ids.length + " persons.");
                else
                console.warn ("Nothing was updated!");
            });
        })
        .catch(error => console.error(error));
    }
};