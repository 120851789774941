<template>
    <div class="mt-4">
        <p class="c2">
                <span class="c1">Meander Immersief</span>
                <span class="c0">&nbsp;is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.</span>
        </p>
        <h2 class="c2" id="h.svjandgnfclh">
                <span class="c3">Persoonsgegevens die wij verwerken</span>
        </h2>
        <p class="c2">
                <span class="c0">Meander Immersief verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt. Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:</span>
        </p>
        <ul class="c4 lst-kix_zav6cj7yros7-0 start">
                <li class="c2 c6 li-bullet-0">
                        <span class="c0">Voor- en achternaam</span>
                </li>
                <li class="c2 c6 li-bullet-0">
                        <span class="c0">E-mailadres</span>
                </li>
        </ul>
        <h2 class="c2" id="h.uhgj8ckzl7j">
                <span class="c3">Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</span>
        </h2>
        <p class="c2">
                <span class="c0">Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als je ervan overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via contact@meanderimmersief.nl, dan verwijderen wij deze informatie.</span>
        </p>
        <h2 class="c2" id="h.asdf309r4oo9">
                <span class="c3">Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</span>
        </h2>
        <p class="c2">
                <span class="c0">Meander Immersief verwerkt jouw persoonsgegevens voor de volgende doelen:</span>
        </p>
        <ul class="c4 lst-kix_zav6cj7yros7-0 start">
                <li class="c2 c6 li-bullet-0">Je te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren</li>
        </ul>
        <h2 class="c2" id="h.nam6c7ghc69f">
                <span class="c3">Hoe lang we persoonsgegevens bewaren</span>
        </h2>
        <p class="c2">
                <span class="c0">Meander Immersief bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor je gegevens worden verzameld. Wij hanteren een bewaartermijn van maximaal 1 jaar voor alle persoonsgegevens.</span>
        </p>
        <p class="c2">
                <span class="c3">Delen van persoonsgegevens met derden</span>
        </p>
        <p class="c2">
                <span class="c0">Meander Immersief verstrekt uitsluitend aan derden en alleen als dit nodig is voor de uitvoering van onze overeenkomst met jou of om te voldoen aan een wettelijke verplichting.</span>
        </p>
        <h2 class="c2" id="h.95w83i61g4dz">
                <span class="c3">Cookies, of vergelijkbare technieken, die wij gebruiken</span>
        </h2>
        <p class="c2">
                <span class="c0">Meander Immersief gebruikt geen cookies of vergelijkbare technieken.</span>
        </p>
        <h2 class="c2" id="h.np1a6zuwbzyp">
                <span class="c3">Gegevens inzien, aanpassen of verwijderen</span>
        </h2>
        <p class="c2">
                <span class="c1">Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door Meander Immersief en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand naar jou of een ander, door jou genoemde organisatie, te sturen. Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van je persoonsgegevens of verzoek tot intrekking van je toestemming of bezwaar op de verwerking van jouw persoonsgegevens sturen naar contact@meanderimmersief.nl. Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou een kopie van je identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie je pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en het Burgerservicenummer (BSN) zwart. Dit ter bescherming van je privacy. We reageren zo snel mogelijk, maar binnen vier weken, op jouw verzoek . Meander Immersief wil je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: </span>
                <span class="c9">
                        <a class="c5" href="https://www.google.com/url?q=https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons&amp;sa=D&amp;source=editors&amp;ust=1686740169742526&amp;usg=AOvVaw3afQe6HStHlWnXi4Z-Lg2Y">https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons</a>
                </span>
        </p>
        <h2 class="c2" id="h.qwr7lkldx6if">
                <span class="c3">Hoe wij persoonsgegevens beveiligen</span>
        </h2>
        <p class="c2">
                <span class="c1">Meander Immersief neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via contact@meanderimmersief.nl</span>
        </p>
        <p class="c2">
                <span class="c0">Contactgegevens:</span>
        </p>
        <p class="c2">
                <span class="c0">Joris Albeda</span>
        </p>
        <p class="c2">
                <span class="c0">contact@meanderimmersief.nl</span>
        </p>
        <p class="c2">
                <span class="c9">
                        <a class="c5" href="https://www.google.com/url?q=https://meanderimmersief.nl/&amp;sa=D&amp;source=editors&amp;ust=1686740169743214&amp;usg=AOvVaw1EGsKwduMbRsv67NY3a2bc">https://meanderimmersief.nl/</a>
                </span>
        </p>
    </div>
</template>

<script>
    export default {
        name: 'PrivacyStatement'
    }
</script>