<template>
  <div class="d-flex justify-center">
    <div class="futursinq">
      <h1>Welkom bij FuturSinq!</h1>
      <p class="mb-6">
        Meld je nu aan voor de fotoshoot. We gebruiken je mail eenmalig om je foto's te sturen!
      </p>
      <v-form ref="form" v-model="isFormValid" @submit.prevent="submitForm" validate-on="input">
        <v-row>
          <v-col cols="8">
            <v-text-field label="Voornaam" v-model="person.name" :rules="nameRules" required></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field label="Tussenvoegsel" v-model="person.preposition"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field label="Achternaam" v-model="person.surname" :rules="nameRules" required></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field label="E-mail (voor de foto's)" v-model="person.email" type="email" :rules="emailRules" required></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col cols="6">
                <div class="d-inline-flex align-center">
                    <v-switch v-model="termsAccepted" class="switch-simple"></v-switch>
                    <span>
                    Ik accepteer de
                        <a> 
                        voorwaarden
                        <v-dialog
                            v-model="showTerms"
                            activator="parent"
                            width="auto"
                        >
                            <v-card>
                            <v-card-text>
                                <terms-conditions />
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="primary" block @click="showTerms = false">Sluiten</v-btn>
                            </v-card-actions>
                            </v-card>
                        </v-dialog>
                        </a>
                    </span>
                </div>
            </v-col>
            <v-col cols="6">
                <div class="d-inline-flex align-center">
                    <v-switch :rules="nameRules" v-model="privacyAccepted" class="switch-simple"></v-switch>
                    <span>
                    Ik accepteer het
                        <a> 
                        privacybeleid
                        <v-dialog
                            v-model="showPrivacy"
                            activator="parent"
                            width="auto"
                        >
                            <v-card>
                            <v-card-text>
                                <h1>Privacy Statement</h1>
                                <p class="mb-4">Jouw privacy is voor ons erg belangrijk. Daarom handelen wij volgens de Algemene Verordening Gegevensbescherming (AVG) en gaan wij zorgvuldig om met de door jou ingevoerde gegevens.</p>
                                <v-expansion-panels>
                                <v-expansion-panel>
                                    <template v-slot:title>
                                    Meer
                                    </template>
                                    <template v-slot:text>
                                    <privacy-statement></privacy-statement>
                                    </template>
                                </v-expansion-panel>
                                </v-expansion-panels>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="primary" block @click="showPrivacy = false">Sluiten</v-btn>
                            </v-card-actions>
                            </v-card>
                        </v-dialog>
                        </a>
                    </span>
                </div>
            </v-col>
        </v-row>
        <div class="mb-4">
          <v-alert
            color="success"
            title="Succes!"
            text="Uw gegevens zijn opgeslagen."
            v-model="showAlert"
            closable
          ></v-alert>
        </div>
        <div>
          <v-btn size="x-large" type="submit" :disabled="!isFormValid">Opslaan</v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { db } from '../db';
import { server } from '../server';
import TermsConditions from './TermsConditions.vue';
import PrivacyStatement from './PrivacyStatement.vue';

export default {
  name: 'RegisterForm',
  props: {
    msg: String
  },
  components: {
    TermsConditions,
    PrivacyStatement
  },

  data() {
    return {
      showAlert: false,
      showTerms: false,
      showPrivacy: false,
      termsAccepted: false,
      privacyAccepted: false,
      person: {},
      isFormValid: false,
      nameRules: [
        v => !!v || 'Dit veld is verplicht',
      ],
      emailRules: [ 
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Voer een geldig e-mailadres in.'
      ]
    }
  },
  methods: {
    async submitForm() {
      try {
        var person = (({name, preposition, surname, email}) => ({name, preposition, surname, email}))(this.person);
        person.onServer = 0;
        this.addPersonLocally(person);
        this.sendPersonToServer(person);
      } catch (error) {
        console.error(`Failed to add ${this.person.name}: ${error}`);
      }
    },

    async sendPersonToServer(person) {
      server.sendPersonList([person]);
    },

    async addPersonLocally(person) {
      
        // Add the new person!
        const id = await db.persons.add(person);

        console.log(`Name ${this.person.name} locally added. Got id ${id}`);

        // Reset form:
        this.person = {};
        this.termsAccepted = false;
        this.privacyAccepted = false;

        //Show alert:
        this.showAlert = true;
        window.setTimeout(() => { this.showAlert = false; }, 4000);
    },
  },
}
</script>
