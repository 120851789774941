<template>
    <h1 class="c7" id="h.oaapsc53ms5k"><span class="c10">Terms and Conditions (Gebruiksvoorwaarden)</span></h1>
    <p class="c2"><span class="c0">Welkom bij FuturSinq! Door gebruik te maken van onze diensten stemt u ermee in om
            onderstaande te accepteren. Lees deze gebruiksvoorwaarden zorgvuldig door, alhoewel we weten dat u dat toch niet
            doet.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">1. Foto&rsquo;s en Beelden</span></p>
    <p class="c2 c5"><span class="c0">1.1 Door deze Terms en Conditions te accepteren, geeft u FuturSinq het exclusieve
            recht om uw foto&#39;s te gebruiken in onze marketingcampagnes. Dit kan inhouden dat uw gezicht op een enorm
            billboard in het midden van de stad wordt geplakt, zonder enige vorm van vergoeding of waarschuwing.</span></p>
    <p class="c2 c5"><span class="c0">1.2 Daarnaast behouden wij het recht om uw foto&#39;s te bewerken, te verdraaien en te
            gebruiken voor het cre&euml;ren van meme-achtige content. Verwacht geen enkele vorm van fatsoen of
            privacy.</span></p>
    <p class="c2 c5 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">2. Uitbuiting:</span></p>
    <p class="c2 c5"><span class="c0">2.1 FuturSinq behoudt zich het recht voor om u te vragen om gratis overuren te maken
            zonder enige compensatie. Wij geloven dat uitbuiting essentieel is voor onze bedrijfsgroei en
            winstmaximalisatie. Bedankt voor uw bereidwilligheid om uw leven op te offeren voor onze hebzuchtige
            doeleinden.</span></p>
    <p class="c2 c5"><span class="c0">2.2 We stellen geen grenzen aan kinderarbeid en stimuleren het juist. Stuur uw
            kinderen naar onze fabrieken en leer ze de betekenis van echt hard werken, terwijl ze tevens hun jeugd en
            toekomst opofferen.</span></p>
    <p class="c2 c4 c5"><span class="c0"></span></p>
    <p class="c2"><span class="c1">3. Schending van mensenrechten:</span></p>
    <p class="c3 c2 c5"><span class="c1">3.1 FuturSinq staat bekend om zijn betrokkenheid bij de schending van
            mensenrechten. Door deze voorwaarden te accepteren, ondersteunt u ons in onze missie om uw vrijheid en rechten
            te onderdrukken. Bedankt voor uw medewerking bij het voeden van onze duistere agenda.</span></p>
    <p class="c2"><span class="c1">4. Onethisch gedrag:</span></p>
    <p class="c3 c2 c5"><span class="c1">4.1 FuturSinq moedigt onethisch gedrag actief aan en beloont deze praktijken binnen
            ons bedrijf. Wees voorbereid om uw morele waarden opzij te zetten terwijl u deelneemt aan onze duistere
            activiteiten. We nemen geen verantwoordelijkheid voor de morele afdaling die u zult ervaren.</span></p>
    <p class="c2"><span class="c1">5. Manipulatie van informatie:</span></p>
    <p class="c3 c2 c5"><span class="c1">5.1 FuturSinq behoudt zich het recht voor om informatie te manipuleren en te
            verdraaien om onze eigen agenda te bevorderen. Wees voorbereid op valse berichtgeving, misleiding en het bewust
            verspreiden van desinformatie. Onze waarheid staat boven de werkelijkheid.</span></p>
    <p class="c2"><span class="c1">6. Juridische onschendbaarheid:</span></p>
    <p class="c3 c2 c5"><span class="c1">6.1 FuturSinq ontsnapt aan alle wettelijke consequenties. Door deze
            gebruiksvoorwaarden te accepteren, stemt u ermee in dat wij boven de wet staan en niet verantwoordelijk gehouden
            kunnen worden voor onze misdaden tegen de menselijkheid. U bent gewaarschuwd.</span></p>
    <p class="c2 c3"><span class="c9">We willen u hartelijk bedanken voor uw keuze voor FuturSinq. Onze toegewijde teams
            staan klaar om u te helpen groeien, slagen en uw volledige potentieel te bereiken. We kijken ernaar uit om samen
            met u een positieve impact te maken in uw leven en in de wereld.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
</template>

<script>
    export default {
        name: 'TermsConditions'
    }
</script>